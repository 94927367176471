import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MaxWidthWrapper, TitleWrapper } from '@common/CommonStyles'

const OuterWrapper = styled(MaxWidthWrapper)`
  max-width: 1250px;
  padding: 50px 0 50px 0;
  ${props => props.theme.media.lessThan('smallDesktop')`
    padding-top: 0;
  `}
`

const PlayerWrapper = styled.div`
  padding: 59.5% 0 0 0;
  position: relative;
  ${props => props.theme.media.lessThan('smallTablet')`
    padding: 80.5% 0 0 0;
  `}
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const WebinarRecordingPlayer = ({ recordSrc }) => {
  return (
    recordSrc && (
      <OuterWrapper>
        {/* <TitleWrapper>Video</TitleWrapper> */}

        <PlayerWrapper>
          <Iframe title='webinarOct2020_record' src={recordSrc} allowFullScreen frameBorder='0' />
        </PlayerWrapper>
      </OuterWrapper>
    )
  )
}

WebinarRecordingPlayer.propTypes = {
  recordSrc: PropTypes.string,
}

WebinarRecordingPlayer.defaultProps = {
  recordSrc: '',
}

export default WebinarRecordingPlayer
