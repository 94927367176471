import React from 'react'
import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import WebinarMarketingOct2020RecordingContainer from '@containers/WebinarMarketingOct2020RecordingContainer'

const IndexPage = () => {
  return (
    <>
      {/* SEO */}
      <WeCreatePageSeoContainer
        seoTitle='Influencer Marketing Fest | weCreate'
        seoDesc='Die Top-Experten zu TikTok, Instagram, YouTube und LinkedIn alle in einem Webinar: das Influencer Marketing Fest von weCreate'
      />

      {/* content container */}
      <WebinarMarketingOct2020RecordingContainer />
    </>
  )
}

export default IndexPage
