import React from 'react'
import styled from 'styled-components'
import WebinarPresentation from '@common/contents/webinarOct2020/WebinarPresentation'
import AboutWecreate from '@common/contents/webinarOct2020/AboutWecreate'
import WebinarRecordingPlayer from '@common/contents/webinarOct2020/WebinarRecordingPlayer'
import Timetable from '@common/contents/webinarOct2020/Timetable'

const Wrapper = styled.div`
  background: ${props => props.theme.color.athensGray} 0% 0% no-repeat padding-box;
`

const BGWrapper = styled.div`
  // url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg')  center left, right top
  background-image: url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg');
  background-position: -25vw -50%, 30vw 50%;
  background-repeat: no-repeat, no-repeat;
  // background-size: contain;
  // background-attachment: fixed;
  background-origin: content-box;
  background-size: auto;
  ${props => props.theme.media.lessThan('mid')`
    background-image: url('../../svgs/flagBcg.svg');
    background-position: center center;
  `}
  ${props => props.theme.media.lessThan('smallTablet')`
    background-image: url('../../svgs/flagBcg.svg');
    background-position: center center;
  `}
`

const WebinarMarketingOct2020RecordingContainer = () => {
  return (
    <Wrapper>
      {/* <PageScrollHorizontalProgressBar /> */}
      <WebinarPresentation showOnlyHeader />
      <BGWrapper>
        {/* TODO: change recordSrc to real recorded video */}
        <WebinarRecordingPlayer recordSrc='https://vimeo.com/showcase/7703609/embed' />
        <Timetable showAsContentList />
        <AboutWecreate />
      </BGWrapper>
    </Wrapper>
  )
}

export default WebinarMarketingOct2020RecordingContainer
